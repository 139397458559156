import { reactive, defineComponent, onMounted, onUnmounted, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import NavBar from "@/presentation/components/navigation/Navigation.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import ProjectTitle from "@/presentation/components/project-title/ProjectTitle.vue";
import OrganizationProfile from "@/presentation/components/organization-profile/OrganizationProfile.vue";
import RoutePath from "@/commons/RoutePath";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import TransformUtil from "@/commons/TransformUtil";
import CommonService from '@/services/CommonService';
import LeftSidePreloader from "@/presentation/components/preloader/left-side-preloader/LeftSizePreloader.vue";
import { getCurrentOrganizationId } from "@/commons/UserStatusUtis";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
export default defineComponent({
    name: "OrganizationProjectDetail",
    components: {
        Breadcrumb: Breadcrumb,
        NavBar: NavBar,
        Textarea: Textarea,
        ProjectTitle: ProjectTitle,
        OrganizationProfile: OrganizationProfile,
        LeftSidePreloader: LeftSidePreloader
    },
    setup: function () {
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var router = useRouter();
        var route = useRoute();
        var routePath = RoutePath;
        var projectId = Number(route.params.id);
        var store = useStore();
        var state = reactive({
            projectId: null,
            listBreadcrumb: [
                {
                    route: "/", name: "ダッシュボード"
                },
                {
                    route: "/project/search", name: "プロジェクト一覧"
                },
                {
                    route: "", name: ""
                }
            ],
            totalQA: 0,
            totalApplicants: 0,
            totalMatch: 0,
            preload: true,
            organizationInfo: {},
            accountId: null,
            projectContractors: [],
            projectCategories: [],
            listAvailableDatetime: [],
            listDegitalSkill: [],
            listBusinessSkill: [],
            filter: {
                prefecture: [],
                category: null,
                available_datetime: [],
                digital_skill: [],
                business_skill: [],
            },
            toggleFilter: {
                prefecture: true,
                category: true,
                available_datetime: true,
                digital_skill: true,
                business_skill: true,
            },
            filterConditionName: {
                category: '選択なし',
                available_datetime: '選択なし',
                digital_skill: '選択なし',
                business_skill: '選択なし',
            },
            teamId: null,
            matchingTeamId: null,
        });
        var matchesTabVisible = computed(function () {
            var _a, _b, _c, _d, _e, _f;
            if (!((_a = store.state.projectInfo) === null || _a === void 0 ? void 0 : _a.project_id) || // まだデータがきていない
                ((_b = store.state.projectInfo) === null || _b === void 0 ? void 0 : _b.project_owner_organization_id) !== getCurrentOrganizationId() || // 自社プロジェクトではない
                ((_c = store.state.projectInfo) === null || _c === void 0 ? void 0 : _c.progress_status) === 'temp_saved' ||
                ((_d = store.state.projectInfo) === null || _d === void 0 ? void 0 : _d.progress_status) === 'stop' ||
                ((_e = store.state.projectInfo) === null || _e === void 0 ? void 0 : _e.progress_status) === 'unapproved' ||
                ((_f = store.state.projectInfo) === null || _f === void 0 ? void 0 : _f.progress_status) === 'rejected' // 一時保存・停止・未承認・差戻し
            ) {
                return false;
            }
            return true;
        });
        var matchesTabNumberVisible = computed(function () { var _a; return ((_a = store.state.projectInfo) === null || _a === void 0 ? void 0 : _a.progress_status) === 'recruiting'; });
        var managementTabVisible = computed(function () {
            var _a, _b, _c;
            if (!((_a = store.state.projectInfo) === null || _a === void 0 ? void 0 : _a.project_id))
                return false; // まだデータがきていない
            if (((_b = store.state.projectInfo) === null || _b === void 0 ? void 0 : _b.progress_status) === 'temp_saved' ||
                ((_c = store.state.projectInfo) === null || _c === void 0 ? void 0 : _c.progress_status) === 'stop')
                return false; // プロジェクト公開前・停止
            if (CommonService.isOrganization())
                return false; // 企業側でない
            if (CommonService.isUser() &&
                (!store.state.projectContractorStatus || store.state.projectContractorStatus === 'NOT_MEMBER'))
                return false; // 人材側かつ参加プロジェクトではない
            return true;
        });
        var teamManagementTabVisible = computed(function () {
            var _a, _b, _c, _d;
            if (!((_a = store.state.projectInfo) === null || _a === void 0 ? void 0 : _a.project_id))
                return false; // まだデータがきていない
            if (((_b = store.state.projectInfo) === null || _b === void 0 ? void 0 : _b.progress_status) === 'temp_saved' ||
                ((_c = store.state.projectInfo) === null || _c === void 0 ? void 0 : _c.progress_status) === 'stop')
                return false; // プロジェクト公開前・停止
            if (CommonService.isUser())
                return false; // 人材側でない
            if (CommonService.isOrganization() &&
                (!store.state.organizationMenuInfo
                    || ((_d = store.state.projectInfo) === null || _d === void 0 ? void 0 : _d.project_owner_account_id) !== store.state.organizationMenuInfo.account_id))
                return false; // 企業側かつプロジェクトのオーナーではない
            return true;
        });
        var teamInfo = computed(function () { return store.state.teamInfo; });
        watch(function () { return teamInfo.value; }, function (newValue) {
            state.teamId = newValue.team_id;
            if (state.teamId !== null) {
                var matchingTeam = state.projectContractors.find(function (contractor) { return contractor.team_id === state.teamId; });
                if (matchingTeam !== undefined) {
                    state.matchingTeamId = matchingTeam.team_id;
                }
            }
        });
        function getDetailProject() {
            organizationProjectInteractor.getOrganizationProjectById(projectId).then(function (result) {
                if (!result.data) {
                    return;
                }
                result.data.working_period_start_at = TransformUtil.convertDatetime(result.data.working_period_start_at);
                result.data.working_period_end_at = TransformUtil.convertDatetime(result.data.working_period_end_at);
                result.data.skillsConvert = "";
                result.data.skills.forEach(function (skill, index) {
                    if (index == 0) {
                        result.data.skillsConvert += skill.name;
                    }
                    else {
                        result.data.skillsConvert += "\u3001" + skill.name;
                    }
                    result.data.skills[index] = skill.id;
                });
                result.data.desired_reward_min_convert = TransformUtil.formatNumber(result.data.desired_reward_min);
                result.data.desired_reward_max_convert = TransformUtil.formatNumber(result.data.desired_reward_max);
                result.data.languageConcat = "";
                result.data.languages.map(function (language, index) {
                    if (index == 0) {
                        result.data.languageConcat += CommonService.mapLanguageName(language);
                    }
                    else {
                        result.data.languageConcat += "\u3001" + CommonService.mapLanguageName(language);
                    }
                });
                state.listBreadcrumb[2] = {
                    name: result.data.title,
                    route: "/project/" + result.data.project_id
                };
                state.totalQA = TransformUtil.formatNumber(result.data.question_count);
                // TODO /api/v1/organizations/projects/{id}のAPIを直したらコメントアウトを外す
                // state.totalMatch = TransformUtil.formatNumber(result.data.matching_count);
                state.totalApplicants = TransformUtil.formatNumber(result.data.applicant_count);
                state.accountId = result.data.project_owner_account_id;
                store.commit('setProjectInfo', result.data);
            }).catch(function (error) {
                router.push(RoutePath.PAGE_404);
            });
        }
        /**
         * カテゴリ一覧取得
         */
        function getListCategories() {
            return projectInteractor.getProjectCategories()
                .then(function (result) {
                state.projectCategories = [{ id: null, name: '選択なし' }].concat(result.data);
            })
                .catch(function (error) {
            });
        }
        /**
         * 対応可能時間帯一覧取得
         */
        function getAvailableDatetimes() {
            return masterInteractor.getAvailableDatetimes().then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listAvailableDatetime = result.data;
            });
        }
        /**
         * デジタルスキル一覧取得
         */
        function getDegitalSkills() {
            return masterInteractor.getSkills({
                "filter[parent]": 1064
            }).then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listDegitalSkill = result.data;
            });
        }
        /**
         * ビジネススキル一覧取得
         */
        function getBusinessSkills() {
            return masterInteractor.getSkills({
                "filter[parent]": 1072
            }).then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listBusinessSkill = result.data;
            });
        }
        function onChangeFilter() {
            // フィルターの状況を別コンポーネントで使うためコミット
            store.commit('setProjectDetailMatchingFilter', state.filter);
        }
        function onToggleFilter(target) {
            var name = '';
            switch (target) {
                case 'category':
                    name = getFilterName(state.projectCategories, [state.filter.category]);
                    break;
                case 'available_datetime':
                    name = getFilterName(state.listAvailableDatetime, state.filter.available_datetime);
                    break;
                case 'digital_skill':
                    name = getFilterSkillName(state.listDegitalSkill, state.filter.digital_skill);
                    break;
                case 'business_skill':
                    name = getFilterSkillName(state.listBusinessSkill, state.filter.business_skill);
                    break;
            }
            state.filterConditionName[target] = name;
            state.toggleFilter[target] = !state.toggleFilter[target];
        }
        /**
         * フィルターを閉じた際の名称取得
         */
        function getFilterName(list, selected) {
            return selected.length ?
                list.filter(function (obj) { return selected.includes(obj.id); })
                    .map(function (obj) { return obj.name; })
                    .join('、')
                : '選択なし';
        }
        /**
         * フィルターを閉じた際の名称取得(スキル)
         */
        function getFilterSkillName(list, selected) {
            return selected.length ?
                list.filter(function (obj) { return selected.includes(obj.skill_id); })
                    .map(function (obj) { return obj.name; })
                    .join('、')
                : '選択なし';
        }
        watch(function () { var _a; return (_a = store.state.projectInfo) === null || _a === void 0 ? void 0 : _a.project_id; }, function (projectId) {
            if (!projectId)
                return;
            if (CommonService.isUser() || CommonService.isOrganization()) {
                organizationProjectInteractor.getContractors(projectId).then(function (result) {
                    if (result === null || result === void 0 ? void 0 : result.data) {
                        state.projectContractors = result.data;
                        store.commit('setProjectContractors', result.data);
                        store.dispatch('detectProjectContractorStatus', result.data);
                        onRouteChange();
                    }
                }).catch(function (error) {
                    store.commit('setProjectContractorStatus', 'NOT_MEMBER');
                });
            }
        });
        watch(function () { return [route.name, route.params.team_id]; }, function () {
            onRouteChange();
        });
        var onRouteChange = function () {
            switch (route.name) {
                case 'OrganizationProjectDetail':
                    state.listBreadcrumb.splice(3, 1);
                    return;
                case 'OrganizationProjectDetailMatches':
                    state.listBreadcrumb[3] = { route: '', name: '希望したチーム一覧' };
                    return;
                case 'OrganizationProjectDetailManagement':
                    state.listBreadcrumb[3] = { route: '', name: 'プロジェクト管理' };
                    return;
                case 'OrganizationProjectDetailContractorManagement':
                    var team = state.projectContractors.find(function (contractor) {
                        return contractor.team_id === Number(route.params.team_id);
                    });
                    state.listBreadcrumb[3] = { route: '', name: team ? team.team_name : '' };
                    return;
            }
        };
        onMounted(function () {
            state.preload = false;
            getListCategories();
            getAvailableDatetimes();
            getDegitalSkills();
            getBusinessSkills();
            if (store.state.projectInfo && Object.keys(store.state.projectInfo).length > 0 && store.state.projectInfo.constructor === Object) {
                state.accountId = store.state.projectInfo.project_owner_account_id;
            }
            else {
                getDetailProject();
            }
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setProjectInfo") {
                    state.accountId = stateVuex.projectInfo.project_owner_account_id;
                    state.totalQA = TransformUtil.formatNumber(stateVuex.projectInfo.question_count);
                    state.totalApplicants = TransformUtil.formatNumber(stateVuex.projectInfo.applicant_count);
                    // TODO /api/v1/organizations/projects/{id}のAPIを直したらコメントアウトを外す
                    // state.totalMatch = TransformUtil.formatNumber(stateVuex.projectInfo.applicant_count);
                }
                if (mutation.type === "setQuestion") {
                    state.totalQA = TransformUtil.formatNumber(stateVuex.projectDetail.totalQuesttian);
                }
            });
            onRouteChange();
        });
        onUnmounted(function () {
            store.commit('setProjectInfo', {});
        });
        return {
            state: state,
            route: route,
            routePath: routePath,
            projectId: projectId,
            matchesTabVisible: matchesTabVisible,
            matchesTabNumberVisible: matchesTabNumberVisible,
            managementTabVisible: managementTabVisible,
            teamManagementTabVisible: teamManagementTabVisible,
            getDetailProject: getDetailProject,
            onChangeFilter: onChangeFilter,
            onToggleFilter: onToggleFilter
        };
    }
});
