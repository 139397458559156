<template>
    <div class="project_detail">
        <NavBar />
        <Breadcrumb :breadcrumb="state.listBreadcrumb" />
         <ProjectTitle />
        <div class="project_detail__container container">
            <div class="project_detail__container__left left-side">
                <OrganizationProfile :accountId="state.accountId"/>
　                <div class="project_detail__filter" v-if="route.name === 'OrganizationProjectDetailMatches'">
                  <div class="project_detail__filter__title" @click="onToggleFilter('available_datetime')">
                    対応可能時間帯
                    <span class="project_detail__filter__title__polygon" v-show="state.toggleFilter.available_datetime"></span>
                    <span class="project_detail__filter__title__polygon__close" v-show="!state.toggleFilter.available_datetime"></span>
                  </div>
                  <div class="project_detail__filter__container" v-show="state.toggleFilter.available_datetime">
                    <div
                        class="form-ctrl"
                        v-for="(available_datetime, idx) in state.listAvailableDatetime"
                        :key="idx"
                    >
                      <div class="form-group">
                        <input
                            type="checkbox"
                            :name="'available_datetime_' + available_datetime.id"
                            :id="'available_datetime_' + available_datetime.id"
                            :value="available_datetime.id"
                            v-model="state.filter.available_datetime"
                            @change="onChangeFilter"
                        />
                        <label :for="'available_datetime_' + available_datetime.id">{{available_datetime.name}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="project_detail__filter__name" v-show="!state.toggleFilter.available_datetime">
                    {{ state.filterConditionName.available_datetime }}
                  </div>
                  <div class="project_detail__filter__title" @click="onToggleFilter('digital_skill')">
                    デジタルスキル
                    <span class="project_detail__filter__title__polygon" v-show="state.toggleFilter.digital_skill"></span>
                    <span class="project_detail__filter__title__polygon__close" v-show="!state.toggleFilter.digital_skill"></span>
                  </div>
                  <div class="project_detail__filter__container" v-show="state.toggleFilter.digital_skill">
                    <div
                        class="form-ctrl"
                        v-for="(digital_skill, idx) in state.listDegitalSkill"
                        :key="idx"
                    >
                      <div class="form-group">
                        <input
                            type="checkbox"
                            :name="'digital_skill_' + digital_skill.skill_id"
                            :id="'digital_skill_' + digital_skill.skill_id"
                            :value="digital_skill.skill_id"
                            v-model="state.filter.digital_skill"
                            @change="onChangeFilter"
                        />
                        <label :for="'digital_skill_' + digital_skill.skill_id">{{digital_skill.name}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="project_detail__filter__name" v-show="!state.toggleFilter.digital_skill">
                    {{ state.filterConditionName.digital_skill }}
                  </div>

                  <div class="project_detail__filter__title" @click="onToggleFilter('business_skill')">
                    ビジネススキル
                    <span class="project_detail__filter__title__polygon" v-show="state.toggleFilter.business_skill"></span>
                    <span class="project_detail__filter__title__polygon__close" v-show="!state.toggleFilter.business_skill"></span>
                  </div>
                  <div class="project_detail__filter__container" v-show="state.toggleFilter.business_skill">
                    <div
                        class="form-ctrl"
                        v-for="(business_skill, idx) in state.listBusinessSkill"
                        :key="idx"
                    >
                      <div class="form-group">
                        <input
                            type="checkbox"
                            :name="'business_skill_' + business_skill.skill_id"
                            :id="'business_skill_' + business_skill.skill_id"
                            :value="business_skill.skill_id"
                            v-model="state.filter.business_skill"
                            @change="onChangeFilter"
                        />
                        <label :for="'business_skill_' + business_skill.skill_id">{{business_skill.name}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="project_detail__filter__name" v-show="!state.toggleFilter.business_skill">
                    {{ state.filterConditionName.business_skill }}
                  </div>
                </div>
            </div>
            <div class="project_detail__container__main main-area">
                <div class="project_detail__container__tab">
                    <router-link
                        :to="routePath.PROJECT_DETAIL + projectId"
                        class="project_detail__container__tab_item"
                        :class="{ active: route.name === 'OrganizationProjectDetail' }"
                    >
                        プロジェクト詳細
                    </router-link>
                    <router-link
                        v-if="matchesTabVisible"
                        :to="routePath.PROJECT_DETAIL + projectId + '/matches'"
                        class="project_detail__container__tab_item"
                        :class="{ active: route.name === 'OrganizationProjectDetailMatches' }"
                    >
                        希望したチーム一覧
                        {{ matchesTabNumberVisible ? ` (${state.totalApplicants})`: '' }}
                    </router-link>
                    <router-link
                        v-if="managementTabVisible && state.teamId"
                        :to="routePath.PROJECT_DETAIL + projectId + '/management/' + state.matchingTeamId"
                        class="project_detail__container__tab_item"
                        :class="{ active: route.name === 'OrganizationProjectDetailManagement' }"
                    >
                        プロジェクト管理
                    </router-link>
                    <router-link
                        v-if="teamManagementTabVisible"
                        v-for="(contractor, teamIndex) in state.projectContractors"
                        :key="teamIndex"
                        :to="routePath.PROJECT_DETAIL + projectId + '/contractor/' + contractor.team_id"
                        class="project_detail__container__tab_item"
                        :class="{ active: route.name === 'OrganizationProjectDetailContractorManagement' && Number(route.params.team_id) === contractor.team_id }"
                    >
                        {{ contractor.team_name }}
                    </router-link>
                </div>
                 <router-view @reloadproject="getDetailProject" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/project-detail/ProjectDetail.ts"></script>
<style lang="scss" src="@/presentation/views/organization/project-detail/ProjectDetail.scss" scoped>
</style>
